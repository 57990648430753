<script>
import VueMultiselect from 'vue-multiselect'

/**
 * @see https://vue-multiselect.js.org/
 */
export default {
  mixins: [VueMultiselect],

  props: {
    /**
     * Used to identify the component in events.
     */
    id: {
      type: String,
      default: () => {
        return `multiselect-id-${Date.now()}`
      }
    },
    /**
     * String to show when pointing to an option
     */
    selectLabel: {
      type: String,
      default: 'Clique para selecionar'
    },
    /**
     * String to show when pointing to an option group
     */
    selectGroupLabel: {
      type: String,
      default: 'Clique para selecionar o grupo'
    },

    /**
     * String to show next to selected option
     */
    selectedLabel: {
      type: String,
      default: 'Selecionado'
    },

    /**
     * String to show when pointing to an already selected option
     */
    deselectLabel: {
      type: String,
      default: 'Clique para remover'
    },

    /**
     * String to show when pointing to an already selected option group
     */
    deselectGroupLabel: {
      type: String,
      default: 'Clique para remover o grupo'
    },

    /**
     * Equivalent to the placeholder attribute on a input
     */
    placeholder: {
      type: String,
      default: 'Selecione'
    },

    /**
     * String to show when highlighting a potential tag
     */
    tagPlaceholder: {
      type: String,
      default: 'Clique para adicionar uma tag'
    },

    /**
     * Multiselect required property
     */
    required: {
      type: Boolean,
      default: false
    },

    /**
     *  Enable/disable highlighting of the pointed value
     */
    showPointer: {
      type: Boolean,
      default: false
    },

    /*
     * Label from option Object, that will be visible in the dropdown.
     */
    label: {
      type: String,
      default: 'label'
    },

    /*
     * Used to compare objects. Only use if options are objects.
     */
    trackBy: {
      type: String,
      default: 'value'
    }
  },

  watch: {
    value: {
      // eslint-disable-next-line no-unused-vars
      handler(value) {
        this.handleRequired()
        this.$emit('triggerFilter', this.value)
      }
    },
    required: {
      handler() {
        this.handleRequired()
      }
    }
  },

  mounted() {
    this.handleRequired()
  },

  methods: {
    handleRequired() {
      const element = document.getElementById(this.id)

      if (this.required && !this.value) {
        element.setAttribute('required', true)
      } else {
        element.removeAttribute('required')
      }
    }
  }
}
</script>

<style lang="scss">
.multiselect {
  color: $primary !important;
  min-height: 45px !important;

  .multiselect--active {
    z-index: 100;
  }

  .multiselect__clear {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    right: 35px;
    top: 8px;
    color: #768192;
  }

  .multiselect__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 45px;
    border: 1px solid #d8dbe0;
    padding: 10px 0.75rem 0px;
    border-radius: 5px;

    div.multiselect__tags-wrap {
      display: flex;
      flex-wrap: wrap;

      .multiselect__tag {
        padding-top: 6px;
        background: $primary;
        margin-bottom: 7px;

        .multiselect__tag-icon {
          color: #fff;
          font-weight: bold;
          background: inherit !important;

          &:after {
            color: #fff;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .multiselect__single {
    padding-top: 2px;
    margin-bottom: 0;
  }

  li.multiselect__element {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #f9f9f9;

    &:hover {
      background-color: #f9f9f9;
    }
  }

  .multiselect__content-wrapper {
    box-shadow: 0 2px 8px rgba(39, 38, 38, 0.29);
  }

  .multiselect__option:not(.multiselect__option--highlight),
  .multiselect__input,
  .multiselect__single,
  .multiselect__input::placeholder {
    color: #768192 !important;
    font-size: 0.8rem !important;
    font-weight: 400;
  }

  .multiselect__placeholder {
    color: rgba(118, 129, 146, 0.5) !important;
    font-size: 0.8rem !important;
    font-weight: 400;
  }

  .multiselect__option--highlight {
    font-size: 0.8rem !important;
    font-weight: 400;
  }

  .multiselect__option--highlight.multiselect__option--selected {
    color: #768192 !important;
  }

  .multiselect__option--selected {
    background: #f6f6f6;
  }

  .multiselect__select {
    z-index: 1;
    min-height: 45px;

    &:before {
      top: 60%;
    }
  }

  .multiselect__clear {
    top: 12px;
  }
}

.multiselect--disabled {
  background-color: #d8dbe0 !important;
  opacity: 1 !important;

  * {
    background-color: #d8dbe0 !important;
    opacity: 1 !important;
  }
}

@media only screen and (max-width: 1400px) {
  .multiselect {
    min-height: 40px !important;

    .multiselect__tags {
      min-height: 40px;
      padding: 5px 0.75rem 0px;

      div.multiselect__tags-wrap {
        .multiselect__tag {
          padding-top: 7px;
        }
      }
    }

    .multiselect__select {
      min-height: 40px;

      &:before {
        top: 60%;
      }
    }

    .multiselect__clear {
      top: 12px;
    }

    .multiselect__option:not(.multiselect__option--highlight),
    .multiselect__placeholder,
    .multiselect__input,
    .multiselect__single,
    .multiselect__input::placeholder,
    .multiselect__option--highlight,
    .multiselect__option:after,
    .multiselect__tags {
      font-size: 0.675rem !important;
    }

    .multiselect__placeholder {
      padding-top: 5px;
    }
  }
}
</style>
